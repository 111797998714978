/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import ApiService from '@/core/services/ApiService'
import JwtService from '@/core/services/JwtService'
import { Actions, Mutations } from '@/store/enums/StoreEnums'
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators'
import { Actions as ProfileActions } from '@/modules/profile/store/Enums'
import { handleError } from '@/modules/common/refreshHandler/refreshHandler'

export interface User {
  id: BigInteger
  username: string
  email: string
  password: string
  AccessToken: string
}

export interface UserAuthInfo {
  errors: unknown
  user: User
  isAuthenticated: boolean
}

export interface RefreshCallback {
  action: string
  params: any
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = ''
  user = {} as User
  isAuthenticated = !!JwtService.getToken()

  /**
   * Get current user object
   * @returns User
   */
  get currentUser (): User {
    return this.user
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated (): boolean {
    return this.isAuthenticated
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors () {
    return this.errors
  }

  @Mutation
  [Mutations.SET_ERROR] (error) {
    this.errors = error
  }

  @Mutation
  [Mutations.SET_AUTH] (array) {
    this.isAuthenticated = true
    this.errors = ''
    if (array.AccessToken) JwtService.saveToken(array.AccessToken)
    if (array.RefreshToken) JwtService.saveRefreshToken(array.RefreshToken)
  }

  @Mutation
  [Mutations.SET_USER] (user) {
    this.user = user
    JwtService.saveUserEmail(user.email)
  }

  @Mutation
  [Mutations.SET_PASSWORD] (password) {
    this.user.password = password
  }

  @Mutation
  [Mutations.PURGE_AUTH] () {
    this.isAuthenticated = false
    this.user = {} as User
    this.errors = ''
    JwtService.destroyToken()
    JwtService.destroyRefreshToken()
    JwtService.destroyUserEmail()
  }

  @Action
  async [Actions.LOGIN] (credentials) {
    return await ApiService.post('api/auth/login', credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data)
        return data.require2fa
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data)
      })
  }

  @Action
  [Actions.LOGOUT] () {
    const id = this.user.id
    this.context.commit(Mutations.PURGE_AUTH)
    if (id) this.context.dispatch(Actions.REVOKE_LAST_SESSION, id)
  }

  @Action
  async [Actions.REGISTER_AGENT] (credentials) {
    return await ApiService.post('api/auth/agent/register', credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data)
        this.context.dispatch(ProfileActions.GET_USER_PROFILE)
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.message)
      })
  }

  @Action
  async [Actions.REGISTER_CUSTOMER] (credentials) {
    return await ApiService.post('api/auth/customer/register', credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data)
        this.context.dispatch(ProfileActions.GET_USER_PROFILE)
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.message)
      })
  }

  @Action
  async [Actions.VERIFY_AUTH] (payload) {
    if (JwtService.getToken() && JwtService.getRefreshToken()) {
      ApiService.setHeader()
      await ApiService.post('api/security/token/verify', payload)
        .then(async () => {
          return this.context.dispatch(Actions.UPDATE_USER)
        })
        .catch(async ({ response }) => {
          const refreshed = await this.context.dispatch(Actions.REFRESH_AUTH)
          if (refreshed) {
            return this.context.dispatch(Actions.UPDATE_USER)
          } else {
            this.context.commit(Mutations.SET_ERROR, response.data.message)
            this.context.commit(Mutations.PURGE_AUTH)
          }
        })
    } else {
      this.context.commit(Mutations.PURGE_AUTH)
    }
  }

  @Action
  async [Actions.REFRESH_AUTH] (cb = {} as RefreshCallback) {
    ApiService.setHeader()
    const formData = new FormData()
    formData.append('refresh_token', JwtService.getRefreshToken() as string)
    formData.append('email', JwtService.getUserEmail() as string)
    return await ApiService.post('api/auth/refresh-token', formData as any)
      .then(async ({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data)
        await this.context.dispatch(Actions.UPDATE_USER)
        if (cb.action) {
          if (cb.params) await this.context.dispatch(cb.action, cb.params)
          else await this.context.dispatch(cb.action)
        }
        return true
      })
      .catch(() => {
        return false
      })
  }

  @Action
  async [Actions.CHANGE_PASSWORD] (formData) {
    return await ApiService.post(
      'api/auth/password/reset/' + formData.token,
      formData.formData
    )
      .then(() => {
        this.context.commit(Mutations.SET_ERROR, '')
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.message)
      })
  }

  @Action
  async [Actions.UPDATE_USER] () {
    ApiService.setHeader()
    return await ApiService.get('api/user')
      .then(({ data }) => {
        this.context.commit(Mutations.SET_USER, data.data)
        return data
      })
      .catch(async ({ response }) => {
        await handleError(
          this,
          response.data,
          Actions.UPDATE_USER,
          null,
          Mutations.SET_ERROR
        )
      })
  }

  @Action
  async [Actions.ENABLE_2FA] () {
    ApiService.setHeader()
    return await ApiService.get('api/security/mfa/enable2fa')
      .then(({ data }) => {
        this.context.dispatch(Actions.UPDATE_USER)
        return data.message
      })
      .catch(async ({ response }) => {
        await handleError(
          this,
          response.data,
          Actions.ENABLE_2FA,
          null,
          Mutations.SET_ERROR
        )
      })
  }

  @Action
  async [Actions.CONFIRM_2FA] (confirm) {
    ApiService.setHeader()
    return await ApiService.post('api/security/mfa/confirm2fa', confirm)
      .then(() => {
        this.context.dispatch(Actions.UPDATE_USER)
        this.context.commit(Mutations.SET_ERROR, '')
      })
      .catch(async ({ response }) => {
        await handleError(
          this,
          response.data,
          Actions.CONFIRM_2FA,
          confirm,
          Mutations.SET_ERROR
        )
      })
  }

  @Action
  async [Actions.DISABLE_2FA] (confirm) {
    ApiService.setHeader()
    return await ApiService.post('api/security/mfa/disable2fa', confirm)
      .then(() => {
        this.context.dispatch(Actions.UPDATE_USER)
        this.context.commit(Mutations.SET_ERROR, '')
      })
      .catch(async ({ response }) => {
        await handleError(
          this,
          response.data,
          Actions.DISABLE_2FA,
          confirm,
          Mutations.SET_ERROR
        )
      })
  }

  @Action
  async [Actions.REVOKE_LAST_SESSION] (id) {
    ApiService.setHeader()
    return await ApiService.get('api/auth/session/' + id + '/last-revoke')
      .then(() => {
        this.context.commit(Mutations.SET_ERROR, {})
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data)
      })
  }
}
