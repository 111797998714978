import { App } from 'vue'
import VueAxios from 'vue-axios'
import JwtService from '@/core/services/JwtService'
import axios, { AxiosResponse, AxiosRequestConfig } from 'axios'

/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App

  /**
   * @description initialize vue axios
   */
  public static init (app: App<Element>) {
    // Add a request interceptor
    axios.interceptors.request.use(function (config) {
      // Do something before request is sent
      return config
    }, async function (error) {
      // Do something with request error
      console.log('Request error')
      return await Promise.reject(error)
    })

    // Add a response interceptor
    axios.interceptors.response.use(function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response
    }, async function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      console.log('Response error')
      return await Promise.reject(error)
    })

    ApiService.vueInstance = app
    ApiService.vueInstance.use(VueAxios, axios)
    ApiService.vueInstance.axios.defaults.baseURL = import.meta.env.VITE_APP_API_URL
  }

  /**
   * @description set the default HTTP request headers
   */
  public static setHeader (): void {
    ApiService.vueInstance.axios.defaults.headers.common.Authorization = `Bearer ${JwtService.getToken()}`
    ApiService.vueInstance.axios.defaults.headers.common.Refresh = `${JwtService.getRefreshToken()}`
    ApiService.vueInstance.axios.defaults.headers.common.AuthEmail = `${JwtService.getUserEmail()}`
    ApiService.vueInstance.axios.defaults.headers.common.Accept =
      'application/json'
    ApiService.vueInstance.axios.defaults.headers.post['Content-Type'] =
      'multipart/form-data'
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static async query (
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return await ApiService.vueInstance.axios.get(resource, params)
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @returns Promise<AxiosResponse>
   */
  public static async get (
    resource: string,
    slug = '' as string
  ): Promise<AxiosResponse> {
    if (slug) return await ApiService.vueInstance.axios.get(`${resource}${slug}`)
    else return await ApiService.vueInstance.axios.get(`${resource}`)
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @returns Promise<AxiosResponse>
   */
  public static async download (
    resource: string,
    slug = '' as string
  ): Promise<AxiosResponse> {
    if (slug) {
      return await ApiService.vueInstance.axios.get(`${resource}/${slug}`, {
        responseType: 'arraybuffer'
      })
    } else {
      return await ApiService.vueInstance.axios.get(`${resource}`, {
        responseType: 'arraybuffer'
      })
    }
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static async post (
    resource: string,
    params: AxiosRequestConfig,
    config?: object
  ): Promise<AxiosResponse> {
    return await ApiService.vueInstance.axios.post(`${resource}`, params, config)
  }

  /**
   * @description send the UPDATE HTTP request
   * @param resource: string
   * @param slug: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static async update (
    resource: string,
    slug: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return await ApiService.vueInstance.axios.put(`${resource}/${slug}`, params)
  }

  /**
   * @description Send the PUT HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static async put (
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return await ApiService.vueInstance.axios.put(`${resource}`, params)
  }

  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public static async delete (resource: string): Promise<AxiosResponse> {
    return await ApiService.vueInstance.axios.delete(resource)
  }
}

export default ApiService
